import _ from 'lodash';
import { AxiosResponse } from 'axios';
import { sqSystemApi } from '@/sdk/api/SystemApi';
import { sqUserGroupsApi } from '@/sdk/api/UserGroupsApi';
import { convertDuration } from '@/datetime/dateTime.utilities';
import { PriorityV1 } from '@/sdk/model/PriorityV1';
import { Timezone } from '@/datetime/timezone.service';
import { errorToast } from '@/utilities/toast.utilities';
import { setSystemMessage, setSystemWarnings } from '@/systemWarning/systemWarning.actions';
import { sqTimezones } from '@/utilities/datetime.constants';
import { BackendDuration, FrontendDuration } from '@/services/systemConfiguration.constants';
import { ServerSpecOutputV1, ServerStatusOutputV1, SupportedUnitsOutputV1 } from '@/sdk/model/models';

interface ConfigData {
  configurationOptions: Record<string, unknown>;
  timeZone?: Timezone;
  defaultMaxInterpolation?: BackendDuration;
  serverSpecs?: Array<ServerSpecOutputV1>;
  defaultMaxCapsuleDuration?: BackendDuration;
  atUserLimit?: boolean;
  priorities?: PriorityV1[];
  isDataLabAvailable?: boolean;
}

let configurationData: ConfigData | undefined;
let supportedUnits: any;
let everyoneGroupEnabled: boolean | undefined = true;

export function fetchConfiguration() {
  return Promise.resolve(sqSystemApi.getServerStatus({ includeInternal: false }))
    .then(({ data }: AxiosResponse<ServerStatusOutputV1>) => {
      const updatedConfigurationOptions = _.transform(
        data.configurationOptions ?? [],
        (memo, { path, value }) => {
          memo[path as string] = value;
        },
        {},
      );
      const offset = data.timeZone?.split(' ')?.[1];

      configurationData = {
        ...data,
        configurationOptions: updatedConfigurationOptions,
        timeZone: sqTimezones.findMostCommonTimeZone(offset),
      };
      setSystemWarnings(data.serverSpecs);
      setSystemMessage(systemMessage());
    })
    .catch((error) => {
      errorToast({ httpResponseOrError: error });
    });
}

export function isConfigurationLoaded() {
  try {
    return configurationData !== undefined;
  } catch {
    return false;
  }
}

// Because we need the user groups API, this has to be done after login
export function fetchEveryoneDisabled() {
  return sqUserGroupsApi.getUserGroups({ nameSearch: 'Everyone' }).then(({ data }) => {
    const everyoneGroup = _.find(data.items, { name: 'Everyone' });
    everyoneGroupEnabled = !_.isUndefined(everyoneGroup) && everyoneGroup.isEnabled;
  });
}

export function assetGroupEditorEnabled() {
  return getConfigurationOption('Features/AssetGroupEditor/Enabled');
}

export function seeqDirectoryEnabled() {
  return getConfigurationOption('Authentication/SeeqDirectory/Enabled');
}

// Memoized since it is not expected to change, but only needed in some tools
export function getSupportedUnits(): Promise<Record<string, string[] | Record<string, string[]> | undefined>> {
  if (_.isUndefined(supportedUnits)) {
    supportedUnits = sqSystemApi.getSupportedUnits().then(({ data }: { data: SupportedUnitsOutputV1 }) => data.units);
  }

  return supportedUnits;
}

function serverConfiguration(): ConfigData {
  if (_.isUndefined(configurationData)) {
    throw new Error('Configuration must be fetched before being accessed');
  }

  return configurationData;
}

export function defaultMaxInterpolation(): FrontendDuration | undefined {
  return convertDuration(serverConfiguration().defaultMaxInterpolation);
}

export function priorityColors(): PriorityV1[] | undefined {
  return serverConfiguration().priorities;
}

export function serverTimezone(): Timezone | undefined {
  return configurationData?.timeZone;
}

export function isDataLabAvailable() {
  return configurationData?.isDataLabAvailable;
}

export function maxScatterPlotSamples() {
  return getConfigurationOption('Features/ScatterPlot/MaxPoints');
}

export function serverSpecs() {
  return serverConfiguration().serverSpecs;
}

export function restrictLogs() {
  return getConfigurationOption('Features/RestrictLogs/Enabled');
}

export function registrationEnabled() {
  return getConfigurationOption('Features/UserRegistration/Enabled');
}

export function authDefaultProviderId() {
  return getConfigurationOption('Authentication/DefaultProviderId');
}

export function authAutoLogin() {
  return getConfigurationOption('Authentication/AutoLogin');
}

export function isTelemetryEnabled() {
  return getConfigurationOption('Features/Telemetry/Enabled');
}

export function isTelemetryAnonymized() {
  return getConfigurationOption('Features/Telemetry/Anonymized');
}

export function adminContactName(): string {
  return getConfigurationOption('Installation/AdminContact/Name');
}

export function adminContactEmail(): string {
  return getConfigurationOption('Installation/AdminContact/Email');
}

export function defaultNumberFormat() {
  return getConfigurationOption('Features/NumberFormat');
}

export function defaultStringFormat() {
  return getConfigurationOption('Features/StringFormat');
}

export function addOnToolsEnabled() {
  return getConfigurationOption('Features/AddOnTools/Enabled');
}

export function systemMessage() {
  return getConfigurationOption('Features/Messages/SystemMessage');
}

export function auditingEnabled() {
  return getConfigurationOption('Features/Auditing/Enabled');
}

export function auditingAllUsersCanRead() {
  return getConfigurationOption('Features/Auditing/AllUsersCanRead');
}

export function dayFirstDefault() {
  return getConfigurationOption('Features/Datafiles/DayFirstDefault') as boolean;
}

export function datasourceManagementEnabled() {
  return getConfigurationOption('Features/DatasourceManagement/Enabled');
}

export function displaysEnabled() {
  return getConfigurationOption('Features/Displays/Enabled');
}

export function delimiterDefault() {
  return getConfigurationOption('Features/Datafiles/DelimiterDefault').toLowerCase();
}

export function officeHoursEnabled() {
  return getConfigurationOption('Features/HomeScreen/OfficeHours/Enabled');
}

export function customSidebar() {
  return getConfigurationOption('Features/HomeScreen/CustomSidebar');
}

export function pluginsEnabled() {
  return getConfigurationOption('Features/Plugins/Enabled');
}

export function hardwareWarningsEnabled() {
  return getConfigurationOption('Features/HardwareWarnings/Enabled');
}

export function isEveryoneGroupEnabled() {
  return everyoneGroupEnabled;
}

export function isAdvancedDateRangeSwapEnabled() {
  return getConfigurationOption('Features/AdvancedDateRangeSwap/Enabled');
}

export function atUserLimit() {
  return configurationData?.atUserLimit || false;
}

export function isSeeqPreviewEnabled() {
  return getConfigurationOption('Features/SeeqPreview/Enabled');
}

export function isMixpanelEnabled() {
  return getConfigurationOption('Features/Mixpanel/Enabled');
}

export function getFeedbackEnabled() {
  return getConfigurationOption('Features/Feedback/Enabled');
}

export function isAnnouncementsEnabled() {
  return getConfigurationOption('Features/Announcements/Enabled');
}

function getConfigurationOption(path: string): any {
  if (!_.has(serverConfiguration().configurationOptions, path)) {
    throw new TypeError(`${path} is not a valid configuration option`);
  }

  return serverConfiguration().configurationOptions[path];
}

export function getDefaultMaxInterpolation(): FrontendDuration | undefined {
  return convertDuration(serverConfiguration().defaultMaxInterpolation);
}

export function getDefaultMaxCapsuleDuration(): FrontendDuration | undefined {
  return convertDuration(serverConfiguration().defaultMaxCapsuleDuration);
}

export function getTableBuilderRefreshRate(): FrontendDuration | undefined {
  return convertDuration({ value: getConfigurationOption('Features/Workbook/TableBuilder/RefreshRate'), uom: 'h' });
}

export function isConditionMonitorNotificationsEnabled(): boolean {
  return getConfigurationOption('Features/Notifications/ConditionMonitors/Enabled');
}

export function isEmailerServiceEnabled(): boolean {
  return getConfigurationOption('Features/EmailerService/Enabled');
}

export function getConditionMonitorNotificationsSchedule(): string {
  return getConfigurationOption('Features/Notifications/ConditionMonitors/Schedule');
}

export function getConditionMonitorNotificationsScheduleDescription(): string {
  return getConfigurationOption('Features/Notifications/ConditionMonitors/ScheduleDescription');
}

export function getLoginMessage(): string {
  return getConfigurationOption('Features/Login/InfoMessage');
}

export function areAttachmentsEnabledForEmailer(): boolean {
  return /maxNumberOfAttachments=[1-9]/.test(getConfigurationOption('Process/EmailerService/AdditionalVMOptions'));
}

// only used for testing
export function resetConfigurationData() {
  configurationData = undefined;
}
